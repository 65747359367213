<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.company") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="grid gap-4">
      <div class="card p-4">
        <div class="grid grid-cols-1 gap-8 p-4 sm:grid-cols-2">
          <div class="flex flex-col gap-y-4">
            <div>
              <label class="block">{{ t("labels.name") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ company.name }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-y-4">
            <div>
              <label class="block">{{ t("labels.licence_validity") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{
                getLocaleDate(locale, company.licenceValidity)
              }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-y-4">
            <div>
              <label class="block">{{ t("labels.email") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ company.email }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-y-4">
            <div>
              <label class="block">{{ t("labels.phone") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{ company.phone }}</span>
            </div>
          </div>
          <div class="flex flex-col gap-y-4">
            <div>
              <label class="block">{{ t("labels.fivaldi_integration") }}</label>
              <span v-if="waiting">
                <skeleton class="h-5 w-full" />
              </span>
              <span v-else>{{
                company.fivaldiId ? t("labels.yes") : t("labels.no")
              }}</span>
            </div>
          </div>
        </div>
        <hr class="my-4 border-slate-300" />
        <div class="flex justify-end">
          <form-button
            :command="() => router.push(`/companies/${company.id}/edit`)"
            :disabled="waiting"
            id="company-edit-button"
            label="edit"
            textVariant="white"
            type="button"
            variant="indigo"
          />
        </div>
      </div>
      <div class="card">
        <custom-table
          :fields="fields"
          :items="users"
          :loading="waiting"
          :pagination="true"
          :perPage="10"
          :searchable="true"
          :searchFields="searchFields"
          :showHeader="true"
          sortBy="lastName"
          :sortFields="sortFields"
          @row-click="
            (id) => router.push(`/companies/${company.id}/personnel/${id}`)
          "
        >
          <template #header>
            <span class="flex justify-end"
              ><form-button
                :command="
                  () => router.push(`/companies/${company.id}/personnel/create`)
                "
                id="header-user-create-button"
                label="create_user"
                textVariant="white"
                type="button"
                variant="indigo"
            /></span>
          </template>
          <template #cell(lastName)="{ item }">
            <div class="flex items-center">
              <img
                v-if="item.avatar"
                class="h-10 w-10 rounded-full object-cover"
                :src="getAvatar(item)"
              />
              <div
                v-else
                class="aspect-square flex h-10 w-10 items-center justify-center rounded-full bg-slate-300"
              >
                <UserIcon class="h-3/4 w-3/4 text-white" />
              </div>
              <span class="ml-5">{{ item.lastName }} {{ item.firstName }}</span>
            </div>
          </template>
          <template #cell(roleId)="{ value }">
            {{ t("roles." + getRole(value)) }}
          </template>
          <template #cell(status)="{ value }">
            {{ t(`status.${value}`) }}
          </template>
        </custom-table>
      </div>
    </div>
  </div>
</template>

<script>
import { UserIcon } from "@heroicons/vue/24/outline";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import Skeleton from "../components/loading/Skeleton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getLocaleDate } from "../utils/dateUtils";
import { getAvatar } from "../utils/imageUtils";
export default {
  components: { Breadcrumb, CustomTable, FormButton, Skeleton, UserIcon },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const fields = ref([
      {
        key: "lastName",
        label: "name",
      },
      {
        key: "roleId",
        label: "role",
      },
      {
        key: "email",
        label: "email",
      },
      {
        key: "phone",
        label: "phone",
      },
      {
        key: "status",
        label: "status",
      },
    ]);

    const searchFields = ref(["email", "firstName", "lastName", "phone"]);

    const sortFields = ref(["email", "lastName"]);

    const company = computed(() => store.state.company.company);
    const roles = computed(() => store.state.role.roles);
    const users = computed(() => store.state.user.users);

    const waiting = computed(() => {
      if (store.state.company.waiting) return true;
      if (store.state.role.waiting) return true;
      if (store.state.user.waiting) return true;
      return false;
    });

    const getRole = (roleId) => {
      const role = roles.value.find((role) => role.id == roleId);
      return role ? role.label : null;
    };

    onMounted(() => {
      if (!company.value.id || company.value.id != route.params.companyId) {
        store.dispatch("company/getCompany", route.params.companyId);
      } else {
        store.dispatch("user/getCompanyUsers", company.value.id);
      }
      if (!roles.length) {
        store.dispatch("role/getRoles");
      }
    });

    watch(
      () => company.value,
      (company) => {
        if (company.id) {
          store.dispatch("user/getCompanyUsers", company.id);
        }
      }
    );

    return {
      company,
      fields,
      getAvatar,
      getLocaleDate,
      getRole,
      locale,
      router,
      searchFields,
      sortFields,
      t,
      users,
      waiting,
    };
  },
};

/*
indigo:
50: "#eef2ff",
100: "#e0e7ff",
200: "#c7d2fe",
300: "#a5b4fc",
400: "#818cf8",
500: "#6366f1",
600: "#4f46e5",
700: "#4338ca",
800: "#3730a3",
900: "#312e81"

pink:
50: "#fdf2f8",
100: "#fce7f3",
200: "#fbcfe8",
300: "#f9a8d4",
400: "#f472b6",
500: "#ec4899",
600: "#db2777",
700: "#be185d",
800: "#9d174d",
900: "#831843"
*/
</script>
